.payment {
  &__link {
    display: flex;
    align-items: flex-start;
  }

  &__text-block {
    opacity: 0;
    height: 0;
    transition-property: height, opacity;
    transition-duration: var(--medium-timing);
    transition-timing-function: var(--default-bezier);
    overflow: hidden;
  }

  &__text-block,
  &__text-wrap,
  &__text {
    display: block;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
  }

  &__item {
    position: relative;
    grid-row-gap: 0;
    border-bottom: 1px solid var(--color-grey-02);
    overflow: hidden;
    align-items: flex-start;

    &:first-child {
      border-top: 1px solid var(--color-grey-02);
    }

    &._opened {
      //&:before,
      //&:after {
      //  transform: rotate(45deg);
      //}

      .payment {
        &__text-block {
          height: auto;
          opacity: 1;
          //z-index: 0;
          //pointer-events: none;
        }

        &__text {
          //height: inherit;
        }

        &__link-text {
          color: var(--color-add-05);
        }

        &__closer{
          &:after{
            opacity: 0;
          }
        }
      }
    }
  }

  &__position,
  &__link-text {
    transition: color var(--medium-timing) var(--default-bezier);
    letter-spacing: 0.36px;
  }

  &__position-wrap {
    display: flex;
    align-items: center;
  }

  &__closer{
    border: 1px solid var(--color-grey-02);
    display: block;
    border-radius: 100%;
    position: relative;

    &:before{
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      height: 2px;
      background: var(--color-main-01);
    }

    &:after{
      content: '';
      position: absolute;
      left: calc(50% - 1px);
      width: 2px;
      background: var(--color-main-01);
      opacity: 1;
      transition: opacity .3s;
    }
  }
}

@include respond-up('large') {
  .payment {
    &__list {
      margin-top: 45px;
      width: var(--grid-column12);
    }

    &__text-block {
      width: var(--grid-column4);
    }

    &__link {
      grid-column: 1/12;
      grid-row: 1;
    }

    &__link-text {
      margin-left: var(--grid-gap);
    }
  }
}

@include respond-up('s-large') {
  .payment {
    &__link {
      padding: 18px 0;
    }

    &__position-wrap {
      width: var(--grid-column5);
      padding-top: 10px;
    }

    &__text-block {
      margin-left: var(--grid-gap);
    }

    &__text-wrap {
      padding-top: 10px;
      padding-bottom: 22px;
    }

    &__closer-wrap{
      width: var(--grid-column1);
      flex: 1 1 auto;
      justify-content: flex-end;
      display: flex;
    }

    &__closer{
      width: 44px;
      height: 44px;
      &:before{
        width: 20px;
        left: calc(50% - 10px);
      }

      &:after{
        height: 20px;
        top: calc(50% - 10px);
      }
    }

    &__text {
      font-size: 16px;
    }
  }
}

@include respond('s-large') {
  .payment {
    &__list {
      margin-top: 40px;
    }

    &__text-block {
      width: var(--grid-column5);
    }

    &__link {
      grid-column: 1/13;
      grid-row: 1;
    }

    &__link-text {
      margin-left: calc(var(--grid-gap) + 21px);
    }
  }
}

@include respond-up('medium') {
  .payment {
    &__position {
      flex: 0 0 var(--grid-column);
    }

    &__link-text {
      flex: 0 0 var(--grid-column4);
    }
  }
}

@include respond('medium') {
  .payment {
    &__list {
      margin-top: 25px;
    }

    &__link {
      grid-column: 1/7;
      padding: 21px 0;
    }

    &__link-text {
      margin-left: var(--grid-gap);
    }

    &__position-wrap {
      width: var(--grid-column6);
    }

    &__text-block {
      width: var(--grid-column4);
      transform: translateX(var(--grid-column));
      margin-left: var(--grid-gap);
    }

    &__text-wrap{
      padding-top: 21px;
      padding-bottom: 14px;
    }

    &__closer-wrap{
      top: 16px;
    }
  }
}

@include respond-down('medium') {
  .payment {
    &__link {
      flex-direction: column;
      position: relative;
    }

    &__closer-wrap{
      position: absolute;
      right: 0;
    }

    &__closer{
      width: 36px;
      height: 36px;
      &:before{
        width: 14px;
        left: calc(50% - 7px);
      }

      &:after{
        height: 14px;
        top: calc(50% - 7px);
      }
    }

    &__text {
      font-size: 15px;
    }
  }
}

@include respond-down('small') {
  .payment {
    &__list {
      margin-top: 18px;
    }

    &__link {
      grid-column: 1/5;
      padding: 19px 0;
    }

    &__text-block {
      width: var(--grid-column3);
    }

    &__text-wrap{
      padding-top: 20px;
      padding-bottom: 11px;
    }

    &__link-text {
      margin-left: 14px;
    }

    &__closer-wrap{
      top: 12px;
    }
  }
}