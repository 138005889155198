.hero {
  &__top {
    position: relative;
  }

  &__images-slider {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: var(--hero-height);

    &::before {
      content: '';
      position: absolute;
      z-index: 25;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 77%;
      background: linear-gradient(180deg, rgba(40, 69, 104, 0) 0%, #2F415B 100%);
      mix-blend-mode: multiply;
    }
  }

  &__picture {
    position: absolute;
    display: flex;
    width: 100vw;
    height: var(--hero-height);
    transition: transform 1.5s var(--default-bezier);

    &_logo {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__images-slide {
    position: absolute;
    display: flex;
    height: 100%;
    z-index: 5;
    overflow: hidden;
    transition: transform 0s, width 0s;
    transition-timing-function: var(--default-bezier);

    &._active {
      z-index: 10;
      width: 100%;
      transform: translate3d(0, 0, 0);
      transition-duration: 1.5s, 0s;
    }

    &._in-next-act {
      animation: next 1.5s var(--default-bezier) forwards;
    }

    &._out-next-act {
      z-index: 15;
      width: 0;
      transform: translate3d(0, 0, 0);
      transition-duration: 0s, 1.5s;
      left: 0;

      .hero {
        &__picture {
          left: 0;
          transform: translate3d(-20%, 0 ,0);
        }
      }
    }

    &._in-prev-act {
      animation: prev 1.5s var(--default-bezier) forwards;
    }

    &._out-prev-act {
      z-index: 15;
      width: 0;
      transform: translate3d(0, 0, 0);
      transition-duration: 0s, 1.5s;
      right: 0;

      .hero {
        &__picture {
          right: 0;
          transform: translate3d(20%, 0 ,0);
        }
      }
    }
  }

  &__texts-slider {
    position: relative;
  }

  &__text-slide {
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: opacity .7s var(--default-bezier);
    pointer-events: none;

    &._active {
      opacity: 1;

      .hero__text-link {
        pointer-events: auto;
      }
    }

    &._in-next-act,
    &._in-prev-act {
      transition-delay: .8s;
    }
  }

  &__text-title {
    color: var(--color-add-04);
  }

  &__text-description {
    font-style: normal;
    font-weight: 400;
    color: var(--color-add-04);
  }

  &__view-modal {
    pointer-events: auto;

    &.button {
      display: none;
    }
  }

  &__slider-content {
    position: absolute;
    bottom: 0;
    z-index: 25;
    width: 100%;
  }

  &__slider-content-inner {
    position: relative;
  }

  &__arrow-left,
  &__arrow-right {
    position: absolute;
    bottom: 0;
  }

  &__arrow-right {
    right: 0;
  }

  &__pagination {
    display: flex;
    margin: 0 -6px;
    position: absolute;
    bottom: 0;
  }

  &__pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: var(--color-add-04-40);
    border-radius: 100%;
    transition: width, background-color;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-bezier);
    margin: 0 6px;

    &._active {
      background-color: var(--color-add-04);
      border-radius: 9px;
      width: 12px;
    }
  }

  &__text-slide-logo {
    display: none;
  }
}

@include respond-up('large') {
  .hero {
    &__slider-info {
      margin-top: 40px;
    }

    &__text-slide-logo {
      width: 150px;
      height: 142px;
      margin-bottom: 80px;
    }
  }
}

@include respond-up('s-large') {
  .hero {
    &__text-description {
      font-size: 17px;
    }

    &__arrow-left {
      right: 68px;
    }

    &__view-modal {
      &.button {
        margin-top: 60px;
      }
    }
  }
}

@include respond('s-large') {
  .hero {
    &__text-slide-logo {
      width: 129px;
      height: 122px;
      margin-bottom: 70px;
    }
  }
}

@include respond-down('s-large') {
  .hero {
    &__slider-info {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .hero {
    &__text-description {
      line-height: 160%;
    }

    &__pagination {
      left: 50%;
      transform: translate3d(-50%, 26px, 0);
    }
  }
}

@include respond('medium') {
  .hero {
    &__text-description {
      font-size: 16px;
    }

    &__view-modal {
      &.button {
        margin-top: 50px;
      }
    }

    &__text-slide-logo {
      width: 100px;
      height: 95px;
      margin-bottom: 40px;
    }
  }
}

@include respond-down('medium') {
  .hero {
    &__arrow-left {
      right: 52px;
    }
  }
}

@include respond-down('small') {
  .hero {
    &__text-description {
      font-size: 13px;
      line-height: 150%;
    }

    &__pagination {
      left: 0;
    }

    &__view-modal {
      &.button {
        margin-top: 30px;
      }
    }

    &__text-slide-logo {
      width: 80px;
      height: 76px;
      margin-bottom: 60px;
    }
  }
}

@keyframes next {
  from {
    transform: translate3d(30%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes prev {
  from {
    transform: translate3d(-30%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}