%text-main {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
}

@include respond-up("s-large") {
  %text-main {
    --font-size: 22px;
  }
}

@include respond("medium") {
  %text-main {
    --font-size: 18px;
  }
}

@include respond-down("small") {
  %text-main {
    --font-size: 17px;
  }
}