.popup-modal-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__main {
    margin-top: 20px;
  }

  &__subtitle {
    margin-top: 10px;
  }

  &__timer {
    margin-top: 20px;
  }
}

@include respond-up('large') {
  .popup-modal-form {
    padding: 110px 40px 40px;

    &__timer {
      margin-top: auto;
    }
  }
}

@include respond-up('s-large') {
  .popup-modal-form {
    &__field {
      &:last-child {
        margin-top: 20px;
      }
    }

    &__policy {
      margin-top: 10px;
    }
  }
}

@include respond('s-large') {
  .popup-modal-form {
    padding: 60px 20px 20px 40px;

    &__title {
      font-size: 26px;
    }

    &__subtitle {
      width: calc(var(--grid-column3) - 40px);
      margin-top: 15px;
    }
  }
}

@include respond-up('medium') {
  .popup-modal-form {
    &__bottom {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .popup-modal-form {

  }
}

@include respond-down('medium') {
  .popup-modal-form {
    &__field {
      &:last-child {
        margin-top: 18px;
      }
    }

    &__button {
      width: 100%;
    }

    &__policy {
      margin-top: 12px;
    }
  }
}

@include respond-down('small') {
  .popup-modal-form {
    padding: 82px 20px 30px;

    &__bottom {
      margin-top: 24px;
    }
  }
}