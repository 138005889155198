.header {
  height: var(--header-height);
  width: 100%;
  z-index: 4700;

  &__container {
    transform: translate3d(0px, 0px, 0px);
    &._sticky {
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      right: 0;
      transform: translate3d(0, -100%, 0);
    }
  }

  &__inner {
    //background-color: var(--color-grey-01);
    background-color: var(--color-add-04);
    transition: background-color, filter;
    transition-duration: var(--default-timing);
    transition-timing-function: linear;
  }

  &__content {
    height: var(--header-height);
    position: relative;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__hamburger-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__logo {
    display: flex;
    align-items: center;
    height: var(--header-height);
    justify-content: flex-start;

    &_white {
      display: none;
    }
  }

  &__phone {
    height: 100%;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-add-05);
    transition: color var(--default-timing) var(--default-bezier);
  }

  &__modal {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__modal-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--color-main-01);
      border-radius: 100%;
      opacity: .4;
    }

    svg path {
      stroke: var(--color-main-01);
    }
  }

  &._sticky {
    .header {
      &__inner {
        //background-color: var(--color-add-04) !important;
        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.1));
      }

      &__phone {
        color: var(--color-add-05) !important;
      }

      &__modal-icon {
        &::before {
          border-color: var(--color-main-01) !important;
        }

        svg path {
          stroke: var(--color-main-01) !important;
        }
      }
    }

    .hamburger {
      &__line {
        //background-color: var(--color-main-01) !important;
      }

      &__title {
        color: var(--color-main-01) !important;
      }
    }
  }

  &._sticky._up {
    .header {
      &__container {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &.hero_one,
  &.hero_two {
    .header {
      &__left {
        justify-content: space-between;
      }

      &__logo {
        width: var(--grid-column2);
      }

      &__choose-apart-link_right {
        display: none;
      }
    }
  }

  &.hero_three,
  &.hero_four,
  &.hero_five {
    .header {
      &__left {
        justify-content: flex-start;
      }

      &__logo {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(0, -50%, 0);
          width: 1px;
          background-color: var(--color-grey-02);
          opacity: .4;
        }
      }

      &__choose-apart-link_left {
        display: none;
      }
    }
  }

  &.hero_four,
  &.hero_five {
    position: fixed;
    pointer-events: none;

    &._opened {
      .hamburger {
        &__line {
          background-color: var(--color-add-05);
        }

        &__title {
          color: var(--color-add-05);
        }
      }

      .header {
        &__content {
          z-index: 700;
        }

        &__logo {
          display: flex;

          &_white {
            display: none;
          }
        }
      }
    }

    &._sticky {
      .drop-menu {
        top: var(--header-height);
        padding-top: unset;
        height: calc(var(--vh, 1vh) * 100 - var(--header-height));

        &::before {
          opacity: 0;
        }
      }

      .header {
        &__inner {
          background-color: var(--color-add-04);
        }

        &__logo {
          display: flex;

          &_white {
            display: none;
          }
        }
      }
    }

    .header {
      &__container {
        pointer-events: auto;
      }

      &__inner {
        background-color: var(--t);
      }

      &__left {
        position: relative;
        z-index: 100;
      }

      &__phone,
      &__modal-title-inner,
      &__modal {
        color: var(--color-add-04);
      }

      &__modal-icon {
        &::before {
          border-color: var(--color-add-04-40);
        }

        svg path {
          stroke: var(--color-add-04);
        }
      }
    }

    .hamburger {
      &__line {
        background-color: var(--color-add-04);
      }

      &__title {
        color: var(--color-add-04);
      }
    }

    .drop-menu {
      top: 0;
      height: calc(var(--vh, 1vh) * 100);
      padding-top: var(--header-height);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: var(--header-height);
        background-color: var(--color-grey-01);
        pointer-events: none;
        transition: width var(--default-timing) var(--default-bezier);
        border-bottom: 1px solid var(--color-grey-02);
      }

      &._opened {
        &::before {
          width: var(--drop-menu-inner-width);
        }
      }
    }

    .header {
      &__logo {
        display: none;

        &_white {
          display: flex;
        }
      }
    }
  }

  &.hero_five {
    .header {
      &__inner {
        border-bottom: 1px solid var(--color-add-04-40);
      }
    }
  }

  &__top-icon {
    height: 100%;
  }

  &__top-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .header {
    &.hero_one,
    &.hero_two {
      .header {
        &__modal_icon {
          display: none;
        }
      }
    }

    &.hero_three,
    &.hero_four,
    &.hero_five {
      .header {
        &__logo {
          padding-left: 35px;
        }
      }
    }

    &__modal {
      margin-left: 32px;
    }
  }
}

@include respond-up('s-large') {
  .header {
    &__left {
      grid-column: 1/8;
    }

    &__right {
      grid-column: 8/13;
    }

    &__modal {
      &_icon {
        .header__modal-icon::before {
          transition: opacity var(--default-timing) var(--default-bezier);
        }

        &:hover {
          .header__modal-icon::before {
            opacity: 1;
          }
        }
      }
    }

    &__choose-apart-link {
      &_left {
        margin-left: 10px;
      }
    }

    &.hero_one,
    &.hero_two {
      .hamburger {
        &:hover {
          .hamburger {
            &__line {
              background-color: var(--color-main-01-50);
            }

            &__title {
              color: var(--color-main-01-50);
            }
          }
        }
      }
    }

    &.hero_three,
    &.hero_four,
    &.hero_five {
      .header {
        &__modal {
          &_title {
            display: none;
          }
        }
      }

      .hamburger {
        &:hover {
          .hamburger {
            &__line_middle {
              width: 21px;
            }
          }
        }
      }
    }

    &__top-icon {
      margin-left: 47px;
      width: 38px;
      height: 38px;
    }

    &__phone {
      margin-left: 56px;
    }

    &__modal-icon {
      width: 40px;
      height: 40px;
    }

    &__logo {
      svg {
        width: 204px;
        height: 32px;
      }
    }
  }
}

@include respond('s-large') {
  .header {
    &.hero_one,
    &.hero_two {
      .header {
        &__modal {
          margin-left: 30px;

          &_title {
            display: none;
          }
        }
      }
    }

    &.hero_three,
    &.hero_four,
    &.hero_five {
      .header {
        &__modal {
          margin-left: 30px;
        }

        &__logo {
          padding-left: 26px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .header {
    &.hero_three,
    &.hero_four,
    &.hero_five {
      .header {
        &__logo {
          &::before {
            height: 40px;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .header {
    &__content {
      align-items: center;
      justify-content: space-between;
      &.grid-block {
        display: flex;
      }
    }

    &__left {
      grid-column: 1/4;
    }

    &__right {
      grid-column: 4/7;
    }

    &.hero_three,
    &.hero_four,
    &.hero_five {
      .header {
        &__logo {
          padding-left: 30px;
        }
      }
    }

    &__top-icon {
      margin-left: 26px;
      width: 30px;
      height: 30px;
    }

    &__modal {
      margin-left: 32px;
    }

    &__logo {
      svg {
        width: 191px;
        height: 30px;
      }
    }
  }
}

@include respond-down('medium') {
  .header {
    &__phone,
    &__modal_title.link {
      display: none;
    }

    &__modal-icon {
      width: 32px;
      height: 32px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .header {
    &__left {
      grid-column: 1/4;
    }

    &__right {
      grid-column: 4/5;
    }

    &.hero_three,
    &.hero_four,
    &.hero_five {
      .header {
        &__logo {
          padding-left: 20px;

          &::before {
            display: none;
          }
        }
      }
    }

    &__top-icon {
      margin-left: 15px;
      width: 21px;
      height: 21px;
    }

    &__choose-apart-link {
      display: none !important;
    }

    &__logo {
      svg {
        width: 128px;
        height: 20px;
      }
    }
  }
}