.floor-selector {
  height: 100%;
  display: flex;

  .arrow {
    &_right,
    &_left {
      transform: rotate(90deg);
    }
  }

  &__counter {
    height: 100%;
    overflow: visible;

    &.swiper {
      margin-left: unset;
    }
  }

  &__counter-item {
    display: flex;
    align-items: center;
  }

  &__counter-item-title {
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
  }
}

@include respond-up('large') {
  .floor-selector {

  }
}

@include respond-up('s-large') {
  .floor-selector {
    flex-direction: column;
    justify-content: space-between;

    &__counter {
      max-height: 266px;
    }

    &__counter-item {
      max-height: 40px;
      justify-content: flex-start;

      + .floor-selector__counter-item {
        .floor-selector__counter-item-title {
          opacity: .2;
        }
      }

      &.swiper-slide-active {
        .floor-selector__counter-item-title {
          opacity: 1;
        }
      }

      &.swiper-slide-prev {
        .floor-selector__counter-item-title {
          opacity: .4;
        }
      }

      &.swiper-slide-next {
        .floor-selector__counter-item-title {
          opacity: .4;
        }

        + .floor-selector__counter-item {
          .floor-selector__counter-item-title {
            opacity: .2;
          }
        }
      }
    }

    &__counter-item-title {
      font-size: 20px;
      transition: opacity .4s ease-in-out;
      opacity: .1;
    }
  }
}

@include respond-up('medium') {
  .floor-selector {

  }
}

@include respond('medium') {
  .floor-selector {

  }
}

@include respond-down('medium') {
  .floor-selector {
    align-items: center;
    flex-direction: row-reverse;

    &__counter-item {
      justify-content: center;
    }

    &__counter-item-title {
      font-size: 16px;
    }

    &__counter {
      max-height: 42px;
      min-width: 102px;

      &.swiper {
        margin: 0;
      }
    }
  }
}

@include respond-down('small') {
  .floor-selector {

  }
}