.common-price-info {
  border-top: 1px solid var(--color-grey-02);
  padding-top: 20px;

  &__item {
    color: var(--color-main-01);
    font-size: 12px;
    font-style: normal;
    line-height: 160%;
  }
}

@include respond-up('medium') {
  .common-price-info {
    &__item {
      font-weight: 400;
      &:last-child {
        margin-top: 4px;
      }
    }
  }
}

@include respond-down('small') {
  .common-price-info {
    &__item {
      font-weight: 500;
      &:last-child {
        margin-top: 9px;
      }
    }
  }
}