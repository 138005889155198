.infrastructure {
  &__wrapper {
    position: relative;
    background-color: var(--background-footer-color);
    overflow: hidden;
  }

  &__top-figure{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }

  &__title{
    z-index: 1;
  }
}

@include respond-up('large') {
  .infrastructure {
    padding: 80px 0;

    &__map-wrap {
      margin-top: 60px;
    }
  }
}

@include respond-up('s-large') {
  .infrastructure {

  }
}

@include respond('s-large') {
  .infrastructure {
    padding: 60px 0;

    &__map-wrap {
      margin-top: 50px;
    }

    &__top-figure{
      svg{
        width: calc(277/1599 * 100vw);
        height: calc(184/1599 * 100vw);
      }
    }
  }
}

@include respond-up('medium') {
  .infrastructure {

  }
}

@include respond('medium') {
  .infrastructure {
    &__map-wrap {
      margin-top: 40px;
    }

    &__top-figure{
      svg{
        width: calc(180/1199 * 100vw);
        height: calc(120/1199 * 100vw);
      }
    }
  }
}

@include respond-down('medium') {
  .infrastructure {
    padding: 40px 0;
  }
}

@include respond-down('small') {
  .infrastructure {
    &__map-wrap {
      margin-top: 25px;
    }

    &__top-figure{
      svg{
        width: 120px;
        height: 80px;
      }
    }
  }
}