.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-add-05-40);
  border-radius: 100%;

  &_right {
    svg {
      transform: rotate(180deg);
    }
  }

  &_light {
    border-color: var(--color-add-04-40);

    svg path {
      stroke: var(--color-add-04);
    }
  }

  &_accent {
    border-color: var(--color-main-01-40);

    svg path {
      stroke: var(--color-main-01);
    }
  }
}

@include respond-up('s-large') {
  .arrow {
    width: 52px;
    height: 52px;

    transition: border-color, background-color;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-bezier);

    &__three {
      display: none;
    }
  }

  .ui {
    &_one {
      .arrow {
        &:hover {
          border-color: var(--color-add-05);
        }

        &_light {
          &:hover {
            border-color: var(--color-add-04);
          }
        }

        &_accent {
          &:hover {
            border-color: var(--color-main-01);
          }
        }
      }
    }

    &_two,
    &_four {
      .arrow {
        &:hover {
          background-color: var(--color-add-05);
          border-color: var(--color-add-05);

          svg path {
            stroke: var(--color-add-04);
          }
        }

        svg path {
          transition: stroke var(--default-timing) var(--default-bezier);
        }

        &_light {
          &:hover {
            background-color: var(--color-add-04);
            border-color: var(--color-add-04);

            svg path {
              stroke: var(--color-main-01);
            }
          }
        }

        &_accent {
          &:hover {
            background-color: var(--color-main-01);
            border-color: var(--color-main-01);
          }
        }
      }
    }

    &_three {
      .arrow {
        &:hover {
          background-color: var(--color-add-05-40);
          border-color: var(--t);
        }

        &_light {
          &:hover {
            background-color: var(--color-add-04-40);
            border-color: var(--t);
          }
        }

        &_accent {
          &:hover {
            background-color: var(--color-main-01-40);
            border-color: var(--t);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .arrow {
    &__two {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .arrow {
    width: 42px;
    height: 42px;

    svg {
      width: 16.5px;
      height: 13px;
    }
  }
}