.project-map {
  &__picture,
  &__main-icon {
    display: flex;
    border-radius: 50px;
    overflow: hidden;
    background-color: white;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
  }

  &__cluster-icon {
    font-family: var(--font);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: var(--color-main-01);
    color: var(--color-add-04);
    transition: all 0.4s;
    @extend %text-main;

    position: relative;
    z-index: 150;

    &._hover, &:hover {
      background-color: var(--color-add-04);
      color: var(--color-main-01);
    }
  }

  &__main-icon-img,
  &__image {
    width: 35px;
    height: 35px;
    object-fit: contain;
  }

  &__object-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    width: 54px;
    position: relative;

    &._hover {
      .project-map__icon-img {
        opacity: 0;
      }
      .project-map__icon-img_hover {
        opacity: 1;
      }
    }
  }

  &__icon-img,
  &__icon-img_hover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity var(--default-timing) var(--default-bezier);
  }

  &__icon-img {

    &_hover {
      opacity: 0;
    }
  }


  &__project-hint {
    display: none;
  }

  &__project-hint-title {
    background: white;
    padding: 2px 3px;
    width: max-content;
    height: fit-content;
  }

  .ymaps-2-1-79-image-with-content-content {
    width: auto !important;
    height: auto !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

@include respond-up('s-large') {
  .project-map {
    margin-top: 50px;
  }
}

@include respond-up('medium') {
  .project-map {

    &__cluster-icon {
      width: 46px;
      height: 46px;
      font-size: 25px;

      &:before {
        height: 54px;
        width: 54px;
      }

      &:after {
        height: 46px;
        width: 46px;
      }
    }
  }
}

@include respond('medium') {
  .project-map {
    margin-top: 30px;
  }
}

@include respond-down('small') {
  .project-map {
    margin-top: 14px;

    &__picture,
    &__main-icon {
      height: 50px;
      width: 50px;
    }

    &__cluster-icon {
      width: 39px;
      height: 39px;
      font-size: 20px;

      &:before {
        height: 45px;
        width: 45px;
      }

      &:after {
        height: 38px;
        width: 38px;
      }
    }
  }
}
