.footer {
  background: var(--background-color);

  &__wrap {
    position: relative;

    &.grid-block {
      grid-row-gap: 0;
    }
  }

  &__contacts-wrap,
  &__info-wrap {
    display: flex;
    flex-direction: column;
  }

  &__files-list,
  &__bottom-wrap {
    display: flex;
  }

  &__schedule-item {
    &:not(:first-child) {
      margin-top: 2px;
    }
  }

  &__address {
    margin-top: 2px;
  }

  &__link-svg {
    svg path {
      fill: var(--color-main-01);
    }
  }

  &__rclass-link {
    color: var(--color-main-01);
  }

  &__bottom-wrap {
    color: #787D7D;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  &__info-wrap {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 155%;
  }

  &__phone {
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    text-transform: uppercase;
  }

  &__email {
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
  }

  &__social-wrap {
    .social-icons {
      &__item {
        border: unset;
        border-radius: unset;
        width: 34px;
        height: 34px;
      }
    }
  }

  &__file-link-text {
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  &__trees-wrap {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__public-offer{
    margin-top: 35px;
    grid-column: 1/7;
    grid-row: 5/6;
  }

  &__public-offer-mob{
    display: none;
  }
}

@include respond-up('large') {
  .footer {
    &__social-wrap,
    &__files-list {
      margin-top: 30px;
    }

    &__bottom-wrap,
    &__link-wrap {
      margin-top: 54px;
    }

    &__trees-wrap {
      right: calc(var(--grid-column3) + var(--grid-gap));
      width: var(--grid-column3);

      svg {
        width: 292px;
        height: 306px;
      }
    }
    &__public-offer{
      margin-top: 35px;
      grid-column: 1/7;
      grid-row: 5/6;
    }
  }
}

@include respond-up('s-large') {
  .footer {
    &__wrap {
      padding: 70px 0 50px;
    }

    &__contacts-wrap {
      grid-column: 1/5;
      margin: -7px;
    }

    &__logo-wrap {
      grid-column: 10/11;
    }

    &__info-wrap {
      grid-column: 10/13;
      margin-top: 19px;
    }

    &__files-list,
    &__bottom-wrap {
      z-index: 10;
      grid-column: 1/9;
    }

    &__social-wrap,
    &__link-wrap {
      grid-column: 10/13;
    }

    &__policy {
      padding: 0 30px;
    }

    &__phone {
      padding: 7px;
      font-size: 30px;
    }

    &__email {
      padding: 7px;
    }

    &__files-list {
      margin-right: -15px;
      margin-bottom: -15px;
      margin-left: -15px;
      flex-wrap: wrap;
    }

    &__email {
      font-size: 20px;
    }

    &__file-link {
      &.link-icon {
        padding: 15px;
        margin: -15px 0 0;

        &:before,
        &:after {
          content: none !important;
        }
      }
    }

    &__file-link-text {
      font-size: 15px;
    }

    &__social-wrap {
      .social-icons {
        &__item {
          &:hover {
            background: var(--t);

            svg {
              g path {
                fill: var(--button-background-hover-color) !important;
              }

              path {
                fill: var(--button-background-hover-color) !important;
              }
            }
          }
        }
      }
    }

    &__bottom-wrap {
      font-size: 14px;
    }

    &__trees-wrap {
      bottom: 0;
    }
  }
}

@include respond('s-large') {
  .footer {
    &__public-offer{
      margin-top: 30px;
      grid-column: 1/8;
      grid-row: 5/6;
    }
    
    &__social-wrap,
    &__files-list {
      margin-top: 25px;
    }

    &__bottom-wrap,
    &__link-wrap {
      margin-top: 37px;
    }

    &__trees-wrap {
      right: var(--grid-column3);
      width: calc(var(--grid-column2) + var(--grid-gap));

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include respond('medium') {
  .footer {
    &__wrap {
      padding: 47px 0 38px;
      overflow: hidden;
    }

    &__contacts-wrap,
    &__logo-wrap,
    &__info-wrap {
      grid-row: 1;
    }

    &__link-wrap,
    &__social-wrap {
      grid-row: 2;
      margin-top: 33px;
    }

    &__files-list,
    &__bottom-wrap {
      grid-row: 3;
      margin-top: 70px;
    }

    &__contacts-wrap {
      grid-column: 2/5;
    }

    &__logo-wrap {
      grid-column: 1/2;
    }

    &__info-wrap,
    &__social-wrap,
    &__bottom-wrap {
      grid-column: 5/7;
    }

    &__files-list {
      grid-column: 1/4;
    }

    &__link-wrap {
      grid-column: 1/3;
    }

    &__email {
      font-size: 18px;
    }

    &__bottom-wrap {
      justify-content: flex-end;
    }

    &__social-wrap {
      .social-icons {
        &__item {
          &:first-child {
            background: var(--t);

            svg {
              g path {
                fill: var(--socials-bg);
              }

              path {
                fill: var(--socials-bg);
              }
            }
          }
        }
      }
    }

    &__trees-wrap {
      grid-row: 3 / 4;
      right: calc(var(--grid-column2) + 10px);
      bottom: -38px;
      width: 183px;
      height: 192px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__phone {
      font-size: 24px;
    }

    &__public-offer-mob{
      padding-top: 20px;
      padding-bottom: 20px;
      grid-column: 1/7;
    }

    &__medium-border{
      border-bottom: 1px solid rgba(198, 204, 206, 1);
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__files-list,
    &__bottom-wrap {
      flex-direction: column;
    }

    &__policy {
      padding: 8px 0;
    }

    &__bottom-wrap {
      font-size: 13px;
    }

    &__contacts-wrap {
      margin: -5px;
    }

    &__phone {
      padding: 5px;
    }

    &__email {
      padding: 5px;
    }

    &__files-list {
      margin-right: -8px;
      margin-bottom: -8px;
      margin-left: -8px;
    }

    &__file-link {
      &.link-icon {
        padding: 8px;
        margin: 0;
      }
    }

    &__file-link-text {
      font-size: 13px;
    }

    &__public-offer{
      display: none;
    }
    &__public-offer-mob{
      display: block;
    }
  }
}

@include respond-down('small') {
  .footer {
    &__wrap {
      padding: 30px 0;
    }

    &__wrap {
      &.grid-block {
        grid-row-gap: 25px;
      }
    }

    &__contacts-wrap,
    &__logo-wrap {
      grid-row: 1;
    }

    &__info-wrap {
      grid-row: 2;
    }

    &__social-wrap {
      grid-row: 3;
    }

    &__link-wrap {
      grid-row: 4;
    }

    &__files-list {
     grid-row: 5;
    }

    &__bottom-wrap {
      grid-row: 6;
    }

    &__logo-wrap {
      grid-column: 1/2;

      svg {
        width: 48px;
        height: 56px;
      }
    }

    &__contacts-wrap,
    &__info-wrap,
    &__social-wrap,
    &__link-wrap {
      grid-column: 2/5;
    }

    &__files-list,
    &__bottom-wrap {
      grid-column: 1/5;
      padding-top: 25px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        border-bottom: 1px solid #C5C5C5;
        width: 100vw;
      }
    }

    &__contacts-wrap {
      justify-content: flex-end;
    }

    &__files-list {
      &:before {
        left: calc(-1 * var(--grid-spacer-and-indent) + 8px);
      }
    }

    &__bottom-wrap {
      &:before {
        left: calc(-1 * var(--grid-spacer-and-indent));
      }
    }

    &__email {
      font-size: 16px;
    }

    &__link-svg {
      &.link-icon__icon-two {
        height: 17px;
      }
    }

    &__trees-wrap {
      left: calc(-1 * var(--grid-spacer-and-indent) - 63px);
      grid-row: 5;
      transform: translate3d(0, -100%, 0);

      svg {
        width: 150px;
        height: 156px;
      }
    }

    &__phone {
      font-size: 20px;
    }

    &__public-offer-mob{
      padding-bottom: 30px;
      grid-column: 1/5;
    }
  }
}