.advantages {
  background: var(--background-color);
  position: relative;

  &__bottom-figure{
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@include respond-up('large') {
  .advantages {
    padding-bottom: 120px;

    &__main {
      margin-top: 60px;
    }
  }
}

@include respond('s-large') {
  .advantages {
    padding-bottom: 100px;

    &__main {
      margin-top: 50px;
    }

    &__bottom-figure{
      svg{
        width: calc(184/1599 * 100vw);
        height: calc(184/1599 * 100vw);
      }
    }
  }
}

@include respond-up('medium') {
  .advantages {

  }
}

@include respond('medium') {
  .advantages {
    &__main {
      margin-top: 40px;
    }

    &__bottom-figure{
      svg{
        width: calc(120/1199 * 100vw);
        height: calc(120/1199 * 100vw);
      }
    }
  }
}

@include respond-down('medium') {
  .advantages {
    padding-bottom: 80px;
  }
}

@include respond-down('small') {
  .advantages {
    &__main {
      margin-top: 25px;
    }

    &__bottom-figure{
      svg{
        width: 80px;
        height: 80px;
        path{}
          &:first-child{
            display: none;
          }
      }
    }
  }
}