h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  text-transform: uppercase;
  font-stretch: 151%;
  font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 60;
  font-style: normal;
}

h1, .h1 {
  line-height: 130%;
}

h1, .h1, h2, .h2, h3, .h3 {
  font-weight: 700;
  line-height: 120%;
}

h2, .h2, h3, .h3 {
  line-height: 135%;
}

h4, .h4 {
  letter-spacing: 0.02em;
}

h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 600;
  line-height: 135%;
}

@include respond-up('large') {
  h1, .h1 {
    --h-size: 55px;
  }

  h2, .h2 {
    --h-size: 50px;
  }

  h3, .h3 {
    --h-size: 36px;
  }
}

@include respond-up("s-large") {
  h4, .h4 {
    --h-size: 18px;
  }

  h5, .h5 {
    --h-size: 18px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond("s-large") {
  h1, .h1 {
    --h-size: 48px;
  }

  h2, .h2 {
    --h-size: 46px;
    &.h2_laptop {
      --h-size: 32px;
    }
  }

  h3, .h3 {
    --h-size: 36px;
    &.h3_laptop {
      --h-size: 26px;
    }
  }

  h4, .h4 {
    &.h4_laptop {
      --h-size: 20px;
    }
  }
}

@include respond("medium") {
  h1, .h1 {
    --h-size: 45px;
  }

  h2, .h2 {
    --h-size: 40px;
  }

  h3, .h3 {
    --h-size: 28px;
  }

  h4, .h4 {
    --h-size: 16px;
  }

  h5, .h5 {
    --h-size: 20px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond-down("small") {
  h1, .h1 {
    --h-size: 28px;
  }

  h2, .h2 {
    --h-size: 32px;
  }

  h3, .h3 {
    --h-size: 24px;
  }

  h4, .h4 {
    --h-size: 16px;
  }

  h5, .h5 {
    --h-size: 18px;
  }

  h6, .h6 {
    --h-size: 14px;
  }
}
