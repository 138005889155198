.layout-price-block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__subtitle {
    color: var(--color-grey-03);
  }

  &__share-wrap {
    display: flex;
    align-items: center;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-grey-02);
    border-radius: 100%;
  }

  &__fav{
    svg {
      path {
        stroke: #C5C5C5;
      }
    }

    &[data-in-fav="true"] {
      svg path {
        fill: var(--color-main-01);
      }
    }
  }

  &__share {
    background-image: url("../images/svg/share.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;

    .ya-share2 {
      background-image: none;

      &__badge_more {
        width: 100%;
        height: 100%;
      }

      &__icon_more {
        width: 100% !important;
        height: 100% !important;
        background-image: url("../images/svg/share.svg") !important;
        background-size: 24px 24px !important;
        background-position: 9px 9px !important;
      }

      &__container {
        width: 100%;
        height: 100%;
      }

      &__link_more {
        width: 44px;
        height: 44px;
        background: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
      }
    }
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
}

@include respond-up('large') {
  .layout-price-block {
    &__subtitle {
      margin-top: 4px;
    }

    &__print {

      &:hover {
        svg {
          path {
            fill: var(--color-main-01);
          }
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .layout-price-block {
    &__link {
      transition: border-color var(--default-timing) var(--default-transition-function);

      &:hover {
        border-color: var(--color-main-01);
      }

      svg {
        path {
          transition: fill var(--default-timing) var(--default-transition-function), stroke var(--default-timing) var(--default-transition-function);
        }
      }
    }

    &__share {
      &:hover {
        svg {
          path {
            stroke: var(--color-main-01);
          }
        }
      }
    }

    &__fav{
      margin-right: 12px;
      &:hover {
        svg {
          path {
            stroke: var(--color-main-01);
          }
        }
      }
    }
  }
}

@include respond-down('s-large') {
  .layout-price-block {
    &__subtitle {
      margin-top: 3px;
    }
  }
}

@include respond-up('medium') {
  .layout-price-block {
    &__link {
      width: 44px;
      height: 44px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__share {
      margin-left: 12px;
    }

    &__title {
      font-size: 28px;
    }
  }
}

@include respond('medium') {
  .layout-price-block {

  }
}

@include respond-down('medium') {
  .layout-price-block {
    &__print {
      //TODO: дать скачивать pdf на мобиле и открыть это
      display: none;
    }
  }
}

@include respond-down('small') {
  .layout-price-block {
    &__subtitle {
      margin-top: 2px;
    }

    &__link {
      width: 38px;
      height: 38px;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    &__share {
      margin-left: 8px;
      background-size: 22px 22px;

      .ya-share2 {
        &__icon_more {
          background-size: 22px 22px !important;
          background-position: 7px 7px !important;
        }

        &__link_more {
          width: 38px !important;
          height: 38px !important;
        }
      }
    }

    &__title {
      font-size: 20px;
    }
  }
}