.hero-info {
  position: relative;
  background: var(--background-color);
  &.animate{
    .hero-info{
      &__description{
        opacity: 1;
      }
    }
  }

  &__address-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__inner {
    &.grid-block {
      grid-row-gap: 0;
    }
  }

  &__preview-wrap {
    position: relative;
    display: flex;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__video-link-wrap {
    display: flex;
    z-index: 2;
  }

  &__video-link {
    display: flex;
    height: max-content;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__address {
    display: flex;
    align-items: center;
  }

  &__address-icon {
    display: flex;

    svg path {
      stroke: var(--color-main-01);
    }
  }

  &__address-title {
    margin-left: 9px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--color-grey-02);

    &:first-child {
      border-top: 1px solid var(--color-grey-02);
    }
  }

  &__item-key {
    font-style: normal;
    font-weight: 500;
    color: var(--color-grey-03);
    font-stretch: expanded;
  }

  &__item-value {
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
    text-transform: uppercase;
    color: var(--color-add-05);
  }

  &__video-link-icon {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &__figure-top{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  &__figure-bottom{
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  &__figure-square {
    position: absolute;
    right: 0;
  }

  &__figure-top, &__figure-bottom, &__figure-square {
      display: flex;
  }

  &__round-button{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 100%;
    background: var(--color-main-01);
    color: #FFFFFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 20.25px */
    letter-spacing: 0.75px;
    text-transform: uppercase;
    transition: var(--default-transition), var(--default-timing);
    &:hover{
      background: #008B90;
    }
  }

  &__description{
    opacity: 0;
    transition: opacity .8s;
  }
}

@include respond-up('large') {
  .hero-info {
    padding-bottom: 120px;
    &__inner {
      padding-top: 80px;
    }

    &__logo {
      margin-top: 60px;
    }

    &__button-wrap{
      margin-top: 55px;
    }

    &__picture {
      height: calc((var(--grid-column5) - 62px) * (493 / 520));
    }
  }
}

@include respond-up('s-large') {
  .hero-info {
    &__address-wrapper {
      grid-row-gap: 30px;
    }

    &__address-wrapper{
      grid-column: 5/12;
    }
    &__list {
      grid-column: 1/7;
    }

    &__video-link-wrap {
      grid-column: 1/4;
      grid-row: 1/3;
    }

    &__title {
      grid-column: 1/4;
      grid-row: 1/2;
    }

    &__logo {
      grid-column: 1/2;
      display: flex;
      height: fit-content;
    }

    &__video-link-wrap {
      justify-content: flex-end;
    }

    &__picture {
      width: calc(var(--grid-column5) - 62px);
    }

    &__video-title {
      margin-top: 14px;
    }

    &__item {
      padding: 20px 0;
    }

    &__item-key {
      font-size: 16px;
      flex: 0 0 var(--grid-column3);
    }

    &__item-value {
      font-size: 18px;
      flex: 0 0 var(--grid-column4);
    }

    &__figure-top {
      svg{
        width: calc(392/1920 * 100vw);
        height: calc(294/1920 * 100vw);
      }
    }

    &__figure-bottom {
      svg{
        width: calc(495/1920 * 100vw);
        height: calc(396/1920 * 100vw);
      }
    }

    &__figure-square {
      bottom: calc(-99/1920 * 100vw);
      svg{
        width: calc(198/1920 * 100vw);
        height: calc(99/1920 * 100vw);
      }
    }

    &__button-wrap{
      display: flex;
      justify-content: flex-end;
    }

    &__round-button{
      width: 152px;
      height: 152px;
    }

    &__addresses-list {
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond('s-large') {
  .hero-info {
    padding-bottom: 100px;
    &__inner {
      padding-top: 60px;
    }

    &__logo {
      margin-top: 50px;
    }

    &__button-wrap{
      margin-top: 50px;
    }

    &__list {
      grid-column: 1/8;
    }

    &__video-link-wrap {
      grid-column: 8/13;
    }

    &__picture {
      height: calc((var(--grid-column5) - 62px) * (392 / 421));
    }
  }
}

@include respond-up('medium') {
  .hero-info {
    &__item-key {
      line-height: 145%;
    }

    &__item-value {
      margin-left: var(--grid-gap);
    }
  }
}

@include respond('medium') {
  .hero-info {
    padding-bottom: 60px;
    &__inner {
      padding-top: 50px;
    }

    &__list,
    &__title{
      grid-column: 1/6;
    }

    &__address-wrapper{
      grid-column: 2/7;
    }

    &__video-title {
      margin-top: 16px;
    }

    &__item {
      padding: 18px 0;
    }

    &__item-key {
      font-size: 15px;
      flex: var(--grid-column2);
    }

    &__item-value {
      font-size: 16px;
      flex: var(--grid-column3);
    }

    &__button-wrap{
      margin-top: 45px;
    }

    &__figure-bottom {
      svg{
        width: calc(300/1199 * 100vw);
        height: calc(240/1199 * 100vw);
      }
    }

    &__figure-square {
      bottom: calc(-60/1199 * 100vw);
      svg{
        width: calc(120/1199 * 100vw);
        height: calc(60/1199 * 100vw);
      }
    }
  }
}

@include respond-down('medium') {
  .hero-info {
    &__address-wrapper {
      grid-row-gap: 20px;
    }

    &__video-link {
      margin-top: 40px;
    }

    &__logo {
      display: none;
    }

    &__figure-top {
      display: none;
    }

    &__round-button{
      width: 124px;
      height: 124px;
      font-size: 13px;
    }
  }
}

@include respond-down('small') {
  .hero-info {
    padding-bottom: 40px;
    &__inner {
      padding-top: 30px;
    }

    &__description {
      margin-top: 15px;
    }

    &__video-link {
      margin-top: 30px;
    }

    &__title,
    &__address-wrapper,
    &__list,
    &__video-link-wrap {
      grid-column: 1/5;
    }

    &__video-title {
      margin-top: 10px;
    }

    &__item {
      padding: 8px 0;
      flex-direction: column;
    }

    &__item-key {
      font-size: 15px;
      line-height: 145%;
    }

    &__item-value {
      font-size: 16px;
      margin-top: 3px;
    }

    &__button-wrap{
      margin-top: 35px;
    }

    &__figure-bottom {
      svg{
        width: 200px;
        height: 160px;
      }
    }

    &__figure-square {
      bottom: -40px;
      svg{
        width: 80px;
        height: 40px;
      }
    }
  }
}