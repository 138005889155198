.hero_five {
  --hero-height: calc(var(--vh, 1vh) * 100);

  &.header {
    .link {
      --link-button-color: var(--color-add-04);
    }

    &._sticky {
      .link {
        --link-button-color: var(--color-main-01);
      }
    }
  }

  .hero {
    &__text-title {
      @extend .h1;
    }

    &__slider-info {
      display: none;
    }

    &__images-slider {
      &::before {
        top: 0;
        height: 50%;
        bottom: unset;
        background: linear-gradient(180deg, #3D4850 0%, rgba(61, 72, 80, 0) 100%);
        mix-blend-mode: multiply;
        opacity: 0.7;
      }
    }

    &__texts-slider {
      display: none;
    }
  }

  .hero-info {
    &__title {
      display: none;
    }
  }
}

@include respond('large') {
  .hero_five{
    .hero-info {
      &__description{
        margin-bottom: 120px;
      }
    }
  }
}

@include respond-up('s-large') {
  .hero_five {
    .hero-info {
      &__inner {
        grid-template-rows: max-content max-content 1fr;
      }

      &__logo,
      &__list {
        grid-row: 3/4;
      }

      &__video-link-wrap {
        grid-column: 8/13;
        grid-row: 3/4;
      }
    }
  }
}

@include respond('s-large') {
  .hero_five {
    .hero-info {
      &__description{
        margin-bottom: 100px;
      }
    }
  }
}

@include respond-up('medium') {
  .hero_five {
    .hero {
      &__pagination,
      &__arrow-right,
      &__arrow-left {
        bottom: 10vh;
      }

      &__arrow-left {
        transform: translate3d(0, 50%, 0);
        left: 0;
      }

      &__arrow-right {
        transform: translate3d(0, 50%, 0);
      }
    }
  }
}

@include respond('medium') {
  .hero_five {
    .hero-info {
      &__description{
        margin-bottom: 60px;
      }
    }
  }
}

@include respond-down('medium') {
  .hero_five{
    .hero-info{
      &__video-link-wrap {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .hero_five {
    --hero-height: max(40vh, 251px);

    .hero {
      &__pagination,
      &__arrow-left,
      &__arrow-right {
        bottom: 5vh;
      }
    }

    .hero-info {
      &__description{
        margin-bottom: 35px;
      }
    }
  }
}