.advantages-infra-slide {
  &__title {
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
    text-transform: uppercase;
  }

  &__description {
    color: #787D7D;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
  }

  &__picture {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .advantages-infra-slide {
    &__picture {
      height: calc(var(--grid-column4) * (455 / 460));
    }
  }
}

@include respond-up('s-large') {
  .advantages-infra-slide {
    &.swiper-slide {
      width: var(--grid-column4);
    }

    &__title {
      font-size: 18px;
      letter-spacing: 0.36px;
    }

    &__description {
      font-size: 16px;
    }

    &__text-wrap {
      margin-top: 25px;
    }

    &__description {
      margin-top: 12px;
    }
  }
}

@include respond('s-large') {
  .advantages-infra-slide {
    &__picture {
      height: calc(var(--grid-column4) * (375 / 379));
    }
  }
}

@include respond('medium') {
  .advantages-infra-slide {
    &__picture {
      height: calc(var(--grid-column3) * (331 / 335));
    }
  }
}

@include respond-down('medium') {
  .advantages-infra-slide {
    &.swiper-slide {
      width: var(--grid-column3);
    }

    &__title {
      font-size: 16px;
      letter-spacing: 0.32px;
    }

    &__description {
      font-size: 15px;
      margin-top: 10px;
    }

    &__text-wrap {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .advantages-infra-slide {
    &__picture {
      height: calc(var(--grid-column3) * (245 / 248));
    }
  }
}