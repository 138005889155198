.plannings-filter {
  &__drop-filter,
  &__drop-icon {
    display: flex;
    align-items: center;
  }

  &__drop-filter-text {
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }

  &.quarter__label,
  &.building__label,
  &.rooms__label,
  &.price__label,
  &.floor-field__label,
  &.areaTotal__label,
  &.section__label {
    color: var(--color-grey-03);
    // text__small
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 155%;
  }

  &.rooms {
    &__values {
      background: var(--tabs-list-background);
      display: flex;
      column-gap: 4px;
      border-radius: var(--button-border-radius);
    }

    &__value {
      padding: 5px 10px;

      input {
        display: none;
      }

      input[type=checkbox]:checked + label {
        background: var(--tabs-active-background);
        color: var(--tabs-active-color);
      }

      .plannings-filter {
        &.rooms__label {
          color: #0C2836;
          padding: 5px 10px;
          margin: -5px -10px;
          cursor: pointer;
          transition-property: color, background;
          transition-duration: var(--default-timing);
          transition-timing-function: var(--default-bezier);
          border-radius: var(--filter-tabs-radius);
          border: 1px solid var(--tabs-border-color);
          font-family: var(--font);
          font-style: normal;
          font-weight: 500;
        }
      }
    }
  }

  &.price,
  &.areaTotal,
  &.floor-field {
    &__wrapper {
      background: var(--filter-range-bg);
      border: 1px solid var(--filter-range-border);
      border-radius: var(--filter-range-radius);
      margin-top: 6px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        background: var(--color-grey-02);
        width: 1px;
        left: 50%;
      }

      .slider-horizontal {
        height: 2px;

        .slider-base {
          background: var(--t);

          .slider-connects {
            .slider-connect {
              background: var(--color-main-01);
            }
          }

          .slider-origin {
            .slider-handle {
              box-shadow: none;
              border: 2px solid var(--color-main-01);
              top: 1px;

              .slider-tooltip {
                display: none;
              }
            }
          }
        }
      }
    }

    &__input {
      font-family: var(--font);
      font-size: var(--font-size);
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      padding: 0;
      margin: 0;
      border-radius: 0;
      border: none;
      background: var(--t);
      width: 50%;

      &:nth-child(2) {
        text-align: end;
      }
    }
  }
}

@include respond-up('large') {
  .plannings-filter {
    &._filter-top {
      .plannings-filter {
        &__filter-button {
          width: var(--grid-column3);
        }

        &__drop-filter {
          margin-left: var(--grid-gap);
        }
      }

      .filter-fieldset {
        &__fields {
          grid-row-gap: 30px;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .plannings-filter {
    --font-size: 16px;

    &__drop-filter-text {
      font-size: 15px;
    }

    &._filter-side,
    &._filter-side-alt {
      position: sticky;
      top: calc(var(--header-height) + 24px);

      .filter-fieldset__field {
        &:not(:first-child) {
          margin-top: 24px;
        }
      }

      .plannings-filter {
        &__buttons-wrap {
          margin-top: 40px;
        }

        &__filter-button {
          width: 100%;
        }

        &__drop-filter {
          margin-top: 30px;
          justify-content: center;
        }
      }
    }

    &._filter-top {
      position: relative;

      .filter-fieldset {
        &__field {
          grid-column: span 3;

          &_activity {
            grid-column: span 6;
            display: flex;
            align-items: flex-end;
          }
        }

        &__fields {
          display: grid;
          grid-template-columns: repeat(var(--grid-columns-number), var(--grid-column));
          grid-column-gap: var(--grid-gap);
        }
      }

      .plannings-filter {
        &__buttons-wrap {
          display: flex;
          align-items: center;
        }
      }
    }

    &.rooms {
      &__values {
        padding: 7px;
      }

      &__value {
        input[type=checkbox]:checked + label {
          &:hover {
            color: var(--tabs-active-color);
          }
        }

        .plannings-filter {
          &.rooms__label {
            font-size: 16px;
            &:hover {
              color: var(--tabs-hover-color);
            }
          }
        }

        &:hover {
          color: var(--tabs-hover-color);
        }
      }
    }

    &.price,
    &.areaTotal,
    &.floor-field {
      &__wrapper {
        padding: 8px 0;

        &:before {
          height: calc(100% - 16px);
          top: 8px;
        }

        .slider-horizontal {
          .slider-base {
            width: calc(100% - 14px);

            .slider-connects {
              top: 9px;
            }

            .slider-origin {
              .slider-handle {
                right: calc(var(--slider-handle-width, 16px) / 2 * -1.9);

                .slider-touch-area {
                  transition: background var(--default-timing) var(--default-bezier);

                  &:hover {
                    background: var(--color-main-01);
                  }
                }
              }
            }
          }
        }
      }

      &__input {
        padding-top: 4px;
        padding-bottom: 4px;

        &:first-child {
          padding-left: 16px;
        }

        &:nth-child(2) {
          padding-right: 16px;
        }
      }
    }
  }

  .ui {
    &_four {
      .plannings-filter {
        &.rooms {
          &__values {
            margin: 0 -7px;
          }
        }
      }
    }
  }
}

@include respond('s-large') {
  .plannings-filter {
    &__drop-filter {
      margin-left: 20px;
    }

    &._filter-top {
      .plannings-filter {
        &__filter-button {
          width: calc(var(--grid-column2) + var(--grid-gap));
        }
      }
    }
  }
}

@include respond-down('s-large') {
  .plannings-filter {
    &._filter-top {
      .filter-fieldset {
        &__fields {
          grid-row-gap: 20px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .plannings-filter {
    &.rooms {
      &__value {
        .plannings-filter {
          &.rooms__label {
            line-height: 160%;
          }
        }
      }

      &__wrapper {
        margin-top: 7px;
      }
    }
  }
}

@include respond('medium') {
  .plannings-filter {
    &._filter-side,
    &._filter-side-alt,
    &._filter-top {
      .filter-fieldset {
        &__fields {
          display: grid;
          grid-template-columns: repeat(var(--grid-columns-number), var(--grid-column));
          grid-column-gap: var(--grid-gap);
          grid-row-gap: 20px;
        }

        &__field {
          grid-column: span 3;

          &_activity {
            grid-column: span 4;
            display: flex;
            align-items: flex-end;
          }
        }
      }

      .plannings-filter {
        &__buttons-wrap {
          display: flex;
          align-items: center;
          margin-top: 10px;
        }

        &__filter-button {
          width: var(--grid-column2);
        }

        &__drop-filter {
          margin-left: var(--grid-gap);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .plannings-filter {
    --font-size: 15px;

    &.rooms {
      &__value {
        .plannings-filter {
          &.rooms__label {
            font-size: 15px;
            line-height: 160%;
          }
        }
      }
    }

    &__drop-filter-text {
      font-size: 14px;
    }

    &.rooms {
      &__values {
        padding: 6px;
      }

      &__wrapper {
        margin-top: 6px;
      }
    }

    &.price,
    &.areaTotal,
    &.floor-field {
      &__wrapper {
        padding: 7px 0;

        &:before {
          height: calc(100% - 16px);
          top: 8px;
        }

        .slider-horizontal {
          .slider-base {
            width: calc(100% - 12px);

            .slider-connects {
              top: 8px;
            }

            .slider-origin {
              .slider-handle {
                right: calc(var(--slider-handle-width, 16px) / 2 * -1.6);
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }

      &__input {
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 15px;

        &:first-child {
          padding-left: 10px;
        }

        &:nth-child(2) {
          padding-right: 10px;
        }
      }
    }
  }

  .ui {
    &_four {
      .plannings-filter {
        &.rooms {
          &__values {
            margin: 0 -6px;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .plannings-filter {
    &.price,
    &.areaTotal,
    &.floor-field {
      &__values {
        display: flex;
      }
    }

    &._filter-side,
    &._filter-side-alt,
    &._filter-top {
      .filter-fieldset__field {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }

      .plannings-filter {
        &__buttons-wrap {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
        }

        &__filter-button {
          width: 100%;
        }

        &__drop-filter {
          margin-top: 20px;
          justify-content: center;
        }
      }
    }
  }
}