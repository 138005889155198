.layout-apartments {
  &__block {
    overflow: hidden;
    height: 0;
    transition: height .4s linear;
  }

  &__inner {
    &._opened {
      .layout-apartments {
        &__link {
          &::after {
            transform: translate3d(0, -50%, 0);
          }
        }
      }
    }
  }

  &__link {
    display: flex;
    position: relative;
    background-color: var(--background-color);
    border-radius: 6px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      background-color: var(--color-main-01);
      height: 2px;
      transition: transform var(--default-timing) var(--default-bezier);
    }

    &::after {
      transform: translate3d(0, -50%, 0) rotate(90deg);
    }
  }

  &__link-title {
    color: var(--color-main-01);
    font-style: normal;
  }

  &__item {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
  }

  &__price {
    flex: 0 1 100%;
    margin-left: var(--grid-gap);
    font-weight: 600;
  }

  &__renovation, &__article {
    color: var(--color-grey-03);
    margin-left: var(--grid-gap);
  }

  &__article{
    white-space: nowrap;
  }
}

@include respond-up('large') {
  .layout-apartments {

  }
}

@include respond-up('s-large') {
  .layout-apartments {
    &__link {
      padding: 12px 20px 13px;
    }

    &__floor {
      flex: 0 0 var(--grid-column);
      padding-left: 19px;
    }

    &__list {
      padding: 7px 0 0;
    }

    &__item {
      padding: 7px 0;
    }
  }
}

@include respond('s-large') {
  .layout-apartments {

  }
}

@include respond-up('medium') {
  .layout-apartments {
    &__link {
      &::before,
      &::after {
        right: 20px;
        width: 20px;
      }
    }

    &__renovation {
      flex: 0 0 var(--grid-column1);
    }

    &__link-title {
      font-size: 17px;
      font-weight: 500;
      line-height: 160%;
    }

    &__item {
      font-size: 13px;
      line-height: 160%;
    }
  }
}

@include respond('medium') {
  .layout-apartments {
    max-width: calc(var(--grid-column5) - var(--grid-spacer));

    &__link {
      padding: 8px 20px;
    }

    &__floor {
      flex: 0 0 calc(var(--grid-column2) - var(--grid-spacer));
      padding-left: 19px;
    }
  }
}

@include respond-down('medium') {
  .layout-apartments {
    &__list {
      padding: 5px 0 0;
    }

    &__item {
      padding: 5px 0;
    }
  }
}

@include respond-down('small') {
  .layout-apartments {

    &__link {
      padding: 11px 14px;

      &::before,
      &::after {
        right: 14px;
        width: 16px;
      }
    }

    &__floor {
      flex: 0 0 var(--grid-column);
      padding-left: 14px;
    }

    &__renovation {
      flex: 0 0 calc(var(--grid-column1) - var(--grid-gap));
    }

    &__link-title {
      font-size: 13px;
      font-weight: 400;
      line-height: 150%;
      font-family: "Nunito Sans", sans-serif;
    }

    &__item {
      font-size: 12px;
      line-height: 145%;
    }
  }
}

// Ой ладно
@media (max-width: 375px) {
  .layout-apartments {
    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}