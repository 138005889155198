.index-offer {
  &.animate{
    .index-offer{
      &__picture{
        transform: scale(1);
        opacity: 1;
      }

      &__button{
        opacity: 1;
      }

      &__text{
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  height: 100vh;
  background: #EEEEED;
  transition: background .1s;
  position: relative;

  &__wrap {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
  }

  &__headers {
    position: sticky;
  }

  &__inner {
    position: relative;
  }

  &__over-wrap {
    position: absolute;
    z-index: 100;
    //height: 277px;
    width: 100vw;
    overflow: hidden;
  }

  &__figure{
    position: absolute;
    right: 0;
    z-index: 100;
  }

  &__block {
    //background-image: url('../images/base/hero/hero.png');
    //background-position: center;
    //background-repeat: no-repeat;
    //background-size: cover;
    //overflow: hidden;
    position: relative;
    overflow: hidden;
  }

  &__text {
    position: absolute;
    top: 10px;
    left: var(--grid-spacer-and-indent);
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 72.8px */
    text-transform: uppercase;
    transform: translateY(30px);
    opacity: 0;
    transition: transform .7s, opacity .7s;

    &_1 {
      color: var(--color-add-01);
    }

    &_2 {
      color: white;
      z-index: 10;
    }
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    transform: scale(1.2);
    transition: transform .7s, opacity .7s;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__button-wrap{
    position: absolute;
    left: var(--grid-spacer-and-indent);
    z-index: 101;
  }

  &__button{
    &.button {
      justify-content: flex-end;
    }

    display: flex;
    align-items: center;
    width: calc(100% + 20px);
    height: 100%;
    position: relative;
    background: var(--color-main-01);
    color: #FFFFFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 20.25px */
    letter-spacing: 0.75px;
    text-transform: uppercase;
    border-radius: 50px;
    padding-left: 25px;
    opacity: 0;
    transition: opacity .7s;
    &:before{
      content: '';
      position: absolute;
      left: 34px;
      width: 6px;
      height: 6px;
      background: #fff;
    }
  }
}
@include respond('large') {
  .index-offer{
    &__headers {
      top: 125px;
      height: 280px;
    }

    &__over-wrap {
      height: 277px;
    }

    &__content {
      padding-top: 277px;
    }

    &__block{
      height: calc(100vh - 277px);
    }

    &__text{
      font-size: 75px !important;
    }

    &__figure{
      top: 115px;
    }

    &__button-wrap{
      bottom: 100px;
    }
  }
}

@include respond-up('s-large') {
  .index-offer {
    &__button-wrap{
      height: 52px;
    }
  }
}

@include respond('s-large') {
  .index-offer{
    &__headers {
      top: 125px;
      height: 280px;
    }

    &__over-wrap {
      height: 249px;
    }

    &__content {
      padding-top: 249px;
    }

    &__block{
      height: calc(100vh - 249px);
    }

    &__text{
      font-size: 56px !important;
    }

    &__figure{
      top: 115px;
      svg{
        width: 345px;
        height: 115px;
      }
    }

    &__button-wrap{
      bottom: 80px;
    }
  }
}

@include respond-up('medium') {
  .index-offer {
    &__figure{
      &_small{
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .index-offer {
    &__headers {
      top: 90px;
      height: 250px;
    }

    &__over-wrap {
      height: 192px;
    }

    &__content {
      padding-top: 192px;
    }

    &__block{
      height: calc(100vh - 192px);
    }

    &__figure{
      svg{
        width: 255px;
        height: 90px;
      }
      top: 80px;
    }
  }
}

@include respond-down('medium') {
  .index-offer {
    &__button-wrap{
      height: 42px;
      bottom: 45px;
    }

    &__button{
      letter-spacing: 0.65px;
      font-size: 13px;

      &.button {
        padding: 12px 28px;
      }

      &:before {
        left: 28px;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
    }
  }
}

@include respond-down('small') {
  .index-offer {
    height: 60vh;

    &__wrap{
      height: 60vh;
    }
    &__headers {
      top: 65px;
      height: 165px;
    }

    &__over-wrap {
      height: 129px;
    }

    &__content {
      padding-top: 129px;
    }

    &__block{
      height: calc(60vh - 129px);
    }

    &__figure{
      &_large{
          display: none;
      }
      svg{
        width: 80px;
        height: 55px;
      }
      top: 65px;
    }

  }
}