.about-item {
  &:first-child {
    .about-item {
      &__tab-item {
        &:first-child {
          margin-top: unset;
        }
      }
    }
  }

  &__tab-item {
    &.animate {
      .about-item {
        &__title-wrap {
          opacity: 1;
          transform: translateY(0);
        }

        &__description-top {
          opacity: 1;
        }
      }
    }
  }

  &__bottom-text {
    &.animate {
      .about-item {
        &__title-bottom, &__description-bottom {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__title-wrap {
    transition: transform .8s, opacity .8s;
    opacity: 0;
    transform: translateY(30px);
  }

  &__description-top {
    opacity: 0;
    transition: opacity .7s;
    transition-delay: .8s;
  }

  &__title-bottom {
    transition: transform .8s, opacity .8s;
    opacity: 0;
    transform: translateY(30px);
  }

  &__description-bottom {
    transition: transform .8s, opacity .8s;
    transition-delay: .5s;
    opacity: 0;
    transform: translateY(30px);
  }

  &__title {
    color: var(--orange-color);
    font-weight: 700;
    display: block;
  }

  &__translate {
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
  }

  &__stretch {
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 27px */
    text-transform: uppercase;
    letter-spacing: 0.16em;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__description-top,
  &__description-bottom {
    color: var(--color-grey-03);
  }

  &__bottom {
    display: flex;
  }

  &__slider {
    position: relative;
  }

  &__content {
    &_no-slider {
      .about-item {
        &__bottom {
          flex-direction: column;
        }
      }
    }
  }

  &__bottom-text {
    display: flex;
    flex-direction: column;
  }

  &__tab-item {
    position: relative;
  }

  &_tabs {
    position: relative;

    .about-item__content {
      &.animate {
        .about-item {
          &__title-wrap {
            opacity: 1;
            transform: translateY(0);
          }

          &__description-top,
          &__description-bottom {
            opacity: 1;
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .about-item {
    &__arrow-left {
      left: 40px;
    }

    &__arrow-right {
      right: 40px;
    }

    &__tab-item {
      margin-top: 140px;
    }

    &__top-figure {
      position: absolute;
      right: calc(-1 * var(--grid-spacer-and-indent));
      bottom: -70px;

      svg {
        width: calc(412 / 1980 * 100vw);
        height: calc(190 / 1980 * 100vw);
      }
    }
  }
}

@include respond-up('s-large') {
  .about-item {
    &__title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 35px;
    }

    &__main-title-wrap {
    }

    &__description-top {
      font-size: 18px !important;
    }

    &__description-bottom {
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 145% !important; /* 23.2px */
    }

    &__slider-wrapper {
      display: grid;
      grid-template-columns: var(--grid-column5) var(--grid-column4);
      grid-column-gap: var(--grid-gap);

      .about-item {
        &__single-image {
          width: var(--grid-column4);
          height: calc(var(--grid-column4) * (327 / 379));
        }

        &__slide.swiper-slide {
          height: calc(var(--grid-column5) * 720 / 582);
        }
      }
    }

    &__slider {
      &_vertical {
        width: var(--grid-column5);
        height: calc(var(--grid-column5) * 720 / 582);
      }
    }

    &__vertical-bottom {
      margin-top: 30px;

      .about-item__description-bottom {
        margin-top: 20px;
      }
    }

    &__slide.swiper-slide {
      height: calc(var(--grid-column9) * (580 / 1072));
    }

    &__single-image {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * (327 / 379));
    }

    &__bottom_with-image {
      .about-item {
        &__bottom-text {
          margin-top: 30px;
          flex: 0 1 var(--grid-column3);
          margin-left: calc(var(--grid-column) + var(--grid-gap) * 2);
        }
      }
    }

    &__content {
      &_no-slider {
        .about-item {
          &__single-image {
            width: var(--grid-column9);
            height: calc(var(--grid-column9) * (580 / 1072));
          }

          &__description-bottom {
            margin-top: 20px;
            margin-left: 0;
          }
        }
      }
    }

    &__title-bottom {
      margin-bottom: 20px;
    }

    &__top-figure {
      position: absolute;
      z-index: 0;
      right: calc(-1 * var(--grid-spacer-and-indent));
      bottom: -160px;

      svg {
        width: calc(412 / 1599 * 100vw);
        height: calc(190 / 1599 * 100vw);
      }
    }

    &__title {
      margin-bottom: -3px;
    }

    &__translate {
      font-size: 16px;
    }

    &__stretch {
      font-size: 20px;
    }

    &_tabs {
      .about-item__contents {
        margin-top: 35px;
      }
    }
  }
}

@include respond('s-large') {
  .about-item {
    &__arrow-left {
      left: 30px;
    }

    &__arrow-right {
      right: 30px;
    }

    &__tab-item {
      margin-top: 120px;
    }
  }
}

@include respond-up('medium') {
  .about-item {
    &__slider {
      margin-top: 30px;
    }

    &__bottom {
      margin-top: 30px;

      &_with-image {
        margin-top: 40px;
      }
    }

    &__single-image {
      flex: 0 0 auto;
    }

    &__arrow-left,
    &__arrow-right {
      position: absolute;
      z-index: 25;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 30px;
      }
    }
  }
}

@include respond('medium') {
  .about-item {
    &__slider-wrapper {
      display: grid;
      grid-template-columns: calc(var(--grid-column3) + var(--grid-column1) / 2 + var(--grid-gap) / 2) calc(var(--grid-column2) + var(--grid-column1) / 2 + var(--grid-gap) / 2);
      grid-column-gap: var(--grid-gap);

      .about-item {
        &__single-image {
          width: calc(var(--grid-column2) + var(--grid-column1) / 2 + var(--grid-gap) / 2);
          height: calc((var(--grid-column2) + var(--grid-column1) / 2 + var(--grid-gap) / 2) / 460 * 386);
        }

        &__slide.swiper-slide {
          height: calc((var(--grid-column3) + var(--grid-column1) / 2 - var(--grid-gap) / 2) * 720 / 582);
        }
      }
    }

    &__slider {
      &_vertical {
        width: calc(var(--grid-column3) + var(--grid-column1) / 2 - var(--grid-gap) / 2);
        height: calc((var(--grid-column3) + var(--grid-column1) / 2 - var(--grid-gap) / 2) * 720 / 582);
      }
    }

    &__vertical-bottom {
      margin-top: 30px;

      .about-item__description-bottom {
        margin-top: 30px;
      }
    }

    &__slide.swiper-slide {
      height: calc(var(--grid-column6) * (384 / 688));
    }

    &__single-image {
      height: calc(var(--grid-column3) * (324 / 375));
    }

    &__bottom_with-image {
      .about-item {
        &__bottom-text {
          flex: 0 1 100%;
          margin-left: calc(var(--grid-gap) * 2);
        }
      }
    }

    &__arrow-left {
      left: 20px;
    }

    &__arrow-right {
      right: 20px;
    }

    &__content {
      &_no-slider {
        .about-item {
          &__single-image {
            width: var(--grid-column6);
            height: calc(var(--grid-column6) * (384 / 688));
          }

          &__description-bottom {
            margin-top: 30px;
            margin-left: 0;
          }
        }
      }
    }

    &__tab-item {
      margin-top: 70px;
    }

    &__title {
      margin-bottom: 18px;
    }

    &__stretch {
      margin-bottom: 30px;
      font-size: 18px;
    }

    &__single-image {
      margin-left: calc(-1 * var(--grid-spacer-and-indent));
    }

    &__slider {
      &.swiper {
        margin-right: calc(-1 * var(--grid-spacer-and-indent));
        margin-left: calc(-1 * var(--grid-spacer-and-indent));
      }
    }

    &__top-figure {
      position: absolute;
      z-index: 0;
      right: calc(-1 * var(--grid-spacer-and-indent));
      bottom: -180px;

      svg {
        width: calc(302 / 1199 * 100vw);
        height: calc(139 / 1199 * 100vw);
      }
    }

    &__description-top {
      font-size: 17px !important;
    }

    &_tabs {
      .about-item__contents {
        margin-top: 30px;
      }
    }
  }
}

@include respond-down('medium') {
  .about-item {
    &__single-image {
      width: var(--grid-column3);
    }

    &__translate {
      margin-bottom: 2px;
    }

    &__title {
      display: block;
    }

    &__title-bottom {
      margin-bottom: 16px;
    }

    &__description-bottom {
      font-size: 15px !important;
      line-height: 145% !important;
      font-weight: 500 !important;
    }

    &__translate {
      font-size: 14px;
    }
  }
}

@include respond-down('small') {
  .about-item {
    &__slider {
      &_vertical {
        //height: calc((var(--grid-column4)) * (414 / 335));
        width: 100%;

        .about-item {
          &__slide.swiper-slide {
            height: calc((var(--grid-column4)) * (414 / 335));
          }
        }
      }
    }

    &__title {
      margin-bottom: 16px;
    }

    &__slider-wrapper {
      .about-item {
        &__description-bottom {
          margin-top: 20px;
        }
      }
    }

    &__tab-item {
      margin-top: 50px;
    }

    //&__tabs {
    //  margin-top: 8px;
    //}

    &__slider {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
    }

    &__vertical-bottom {
      display: flex;
      flex-direction: column-reverse;
    }

    &__bottom {
      margin-top: 20px;
      flex-direction: column-reverse;

      &_with-image {
        margin-top: 30px;
      }
    }

    &__slide.swiper-slide {
      height: calc(var(--grid-column4) * (186 / 335));
    }

    &__single-image {
      height: calc(var(--grid-column3) * (231 / 268));
      margin-top: 20px;

      &_wide {
        width: 100%;
        height: calc(var(--grid-column4) * (186 / 335));
      }
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 60px;
      }
    }

    &__slider-list {
      flex: 0 0 100%;
    }

    &__arrow-left {
      margin-top: 10px;
      margin-left: var(--grid-spacer);
    }

    &__arrow-right {
      margin-top: 10px;
      margin-left: 10px;
    }

    &__slider {
      .about-item {
        &__arrow-left,
        &__arrow-right {
          border-color: rgba(#000000, 0.4);

          svg {
            path {
              stroke: #000000;
            }
          }
        }
      }
    }

    &__content {
      &_no-slider {
        .about-item {
          &__single-image {
            width: var(--grid-column4);
            height: calc(var(--grid-column4) * (186 / 335));
            margin-top: 0;
            margin-left: var(--grid-spacer);
          }

          &__description-bottom {
            margin-top: 20px;
          }
        }
      }
    }

    &__content {
      margin: 0 calc(0px - var(--grid-spacer));
    }

    &__bottom-text,
    &__description-top {
      margin: 0 var(--grid-spacer);
    }

    &__stretch {
      margin-bottom: 26px;
      font-size: 16px;
    }

    &__top-figure {
      position: absolute;
      z-index: 0;
      right: calc(-1 * var(--grid-spacer-and-indent));
      bottom: -50px;

      svg {
        width: 252px;
        height: 100px
      }
    }

    &__description-top {
      font-size: 16px !important;
    }

    &_tabs {
      .about-item {
        &__contents {
          margin-top: 23px;
        }

        &__title-wrap,
        &__description-bottom {
          padding: 0 var(--grid-spacer);
        }
      }
    }
  }
}