.layout-modal-base {
  &__main {
    display: grid;
  }

  &__form-wrap {
    background-color: var(--background-color);
  }

  &__subtitle {
    color: var(--color-grey-03);
    margin-top: 4px;
  }

  &__title-wrap {
    display: flex;
  }

  &__title {
    position: relative;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
    text-transform: uppercase;
  }

  &__sber-action-link {
    top: 0;
  }
}

@include respond-up('large') {
  .layout-modal-base {
    &__right {
      padding-top: 60px;
    }
  }
}

@include respond-up('s-large') {
  .layout-modal-base {
    width: var(--grid-column12);

    &__main {
      padding: 0 calc(var(--grid-column) + var(--grid-gap)) 40px;
      grid-template-columns: repeat(10, var(--grid-column));
      grid-template-rows: auto 1fr;
      grid-column-gap: var(--grid-gap);
    }

    &__form-wrap {
      padding: 0 calc(var(--grid-column) + var(--grid-gap));
    }

    &__heading {
      grid-column: 1/6;
      grid-row: 1/2;
      width: calc(var(--grid-column5) - 40px);
    }

    &__left {
      position: relative;
      grid-column: 1/6;
      grid-row: 2/4;
      padding-right: 40px;
      display: flex;
      flex-direction: column;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 1px;
        height: calc(100% + 31px);
        background-color: var(--color-grey-02);
      }
    }

    &__right {
      grid-column: 6/11;
      grid-row: 1/3;
    }

    &__sber-action-link {
      left: calc(100% + 20px);
    }
  }
}

@include respond('s-large') {
  .layout-modal-base {
    &__right {
      padding-top: 54px;
    }
  }
}

@include respond('medium') {
  .layout-modal-base {
    width: var(--grid-column6);

    &__sber-action-link {
      left: calc(100% + 10px);
    }
  }
}

@include respond-up('medium') {
  .layout-modal-base {
    &__title {
      font-size: 22px;
    }
  }
}

@include respond-down('medium') {
  .layout-modal-base {
    &__main {
      padding: 0 var(--grid-spacer) 30px;
      grid-template-columns: 1fr;
    }

    &__form-wrap {
      padding: 0 var(--grid-spacer);
    }

    &__heading {
      grid-column: 1/2;
      grid-row: 1/2;
    }

    &__left {
      grid-column: 1/2;
      grid-row: 3/4;
    }

    &__right {
      grid-column: 1/2;
      grid-row: 2/3;
    }
  }
}

@include respond-down('small') {
  .layout-modal-base {
    width: 100vw;

    &__sber-action-link {
      right: -44px;
    }

    &__title {
      font-size: 18px;
    }
  }
}