.header-choose-apart-link-iconless {
  display: flex;
  align-items: center;
  height: 100%;

  font-family: var(--font);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}