.hamburger {
  display: flex;
  align-items: center;
  height: 100%;

  &__icon {
    display: flex;
    position: relative;
  }
  &__icon-main{
    transition: opacity var(--default-timing);
  }
  &__line {
    opacity: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-add-01);
    position: absolute;
    left: 0;
    transition: opacity, transform, background-color, width;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-bezier);

    &_top {
      top: 0;
    }

    &_middle {
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    &_bottom {
      bottom: 0;
    }
  }

  &__title {
    margin-left: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: var(--color-add-01);
    transition: color var(--default-timing) var(--default-bezier);
  }

  &._opened {
    .hamburger {
      &__icon-main{
        opacity: 0;
      }
      &__line {
        opacity: 1;
        &_top {
          transform: rotate(45deg) translate3d(5px, 5px, 0);
        }

        &_middle {
          opacity: 0;
        }

        &_bottom {
          transform: rotate(-45deg) translate3d(5px, -5px, 0);
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .hamburger {
    padding: 0 35px;
    margin-left: -35px;

    &:hover {
      .hamburger {
        &__icon-main {
          animation: right 2s linear infinite;
        }
      }
    }

    &__icon-main{
      svg{
        display: none;
      }

      //width: 30px;
      width: 36px;
      height: 15px;
      background: {
        image: url('../images/svg/mini-wave-new.svg');
        //size: 40px 15px;
        size: 46px 15px;
        repeat: repeat;
      };
    }
  }
}

@include respond-up('medium') {
  .hamburger {
    &__icon {
      width: 35px;
      height: 16px;
    }
  }
}

@include respond('medium') {
  .hamburger {
    padding: 0 30px;
    margin-left: -30px;
  }
}

@include respond-down('medium') {
  .hamburger {
    &__title {
      display: none;
    }
  }
}

@include respond-down('small') {
  .hamburger {
    padding: 0 14px;
    margin: 0 -14px;

    &__icon {
      width: 24px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__icon-main{
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        width: 20px;
        height: 18px;
      }
    }

    &._opened {
      .hamburger {
        &__line {
          &_top {
            transform: rotate(45deg) translate3d(4px, 4px, 0);
          }

          &_middle {
            opacity: 0;
          }

          &_bottom {
            transform: rotate(-45deg) translate3d(4px, -4px, 0);
          }
        }
      }
    }
  }
}

@keyframes right {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 70px;
  }
}