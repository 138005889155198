%text-body {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

@include respond-up("medium") {
  %text-body {
    --font-size: 18px;
  }
}

@include respond-down("small") {
  %text-body {
    --font-size: 16px;
  }
}