.text {
  // colors

  &__black-text {
    color: var(--color-add-01);
  }

  &__white-text {
    color: var(--color-add-04);
  }

  &__grey03 {
    color: var(--color-grey-03);
  }

  &__main01 {
    color: var(--color-main-01);
  }

  // styles

  &__main {
    @extend %text-main;
  }

  &__new-main {
    @extend %text-new-main;
  }

  &__medium {
    @extend %text-medium;
  }

  &__body {
    @extend %text-body;
  }

  &__mini {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
  }

  &__main15{
    @extend %text-main;
  }

  &__main14{
    @extend %text-main;
  }

  &__small {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
  }

  // TODO: rewrite
  &__error-page {
    font-family: var(--font);
    font-size: var(--font-size);
    font-style: normal;
    color: var(--primary-color);
    font-variation-settings: var(--font-settings), 'opsz' 60;
    font-stretch: 151%;
    font-weight: 600;
    line-height: 120%;
  }

  &__public-offer{
    font-family: var(--font);
    font-weight: 400;
    color: rgb(120, 125, 125);
    line-height: 135%;
    font-size: 11px;
  }
}

@include respond-up("s-large") {
  .text {
    &__small {
      font-size: 14px;
      line-height: 155%;
    }

    &__main15{
      font-size: 18px !important;
    }
  }
}

@include respond-up("medium") {
  .text {
    &__error-page {
      --font-size: 200px;
    }

    &__main14{
      font-size: 14px !important;
    }
  }
}

@include respond-down("medium") {
  .text {
    &__small {
      font-size: 13px;
      line-height: 150%;
    }

    &__main15{
      font-size: 15px !important;
    }

    &__public-offer{
      font-size: 10px;
    }
  }
}

@include respond-down("small") {
  .text {
    &__error-page {
      --font-size: 80px;
    }

    &__main14{
      font-size: 13px !important;
    }
  }
}
