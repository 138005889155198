.index-page {
  &__blocks {
    display: flex;
    flex-direction: column;
  }

  &__block {
    &_plannings{
      background: var(--background-color);
      position: relative;
    }
  }

  &__figure-top{
    position: absolute;
    top: 0;
    right: 0;
  }

  &__figure-bottom{
    position: absolute;
    bottom: -120px;
    left: 0;
  }
}

@include respond-up('large') {
  .index-page {
    &__blocks {
      padding-bottom: 120px;
    }

    &__block {
      padding-top: 120px;
    }

    &__mortgage {
      margin-top: 63px;
    }
  }
}

@include respond('s-large') {
  .index-page {
    &__blocks {
      padding-bottom: 100px;
    }

    &__block {
      padding-top: 100px;
    }

    &__mortgage {
      margin-top: 80px;
    }

    &__figure-top{
      svg{
        width: calc(392/1599 * 100vw);
        height: calc(196/1599 * 100vw);
      }
    }

    &__figure-bottom{
      svg{
        width: calc(295/1599 * 100vw);
        height: calc(470/1599 * 100vw);
      }
    }
  }
}

@include respond('medium') {
  .index-page {
    &__block {
      padding-top: 80px;
    }

    &__mortgage {
      margin-top: 60px;
    }

    &__blocks {
      padding-bottom: 80px;
    }

    &__figure-top{
      svg{
        //width: calc(240/1199 * 100vw);
        //height: calc(120/1199 * 100vw);

        width: clamp(240px, 240 * 100vw / 768, 300px);
        height: clamp(120px, 120 * 100vw / 768, 150px);
      }
    }
  }
}

@include respond-down('medium') {
  .index-page{
    &__figure-bottom{
      display: none;
    }
  }
}

@include respond-down('small') {
  .index-page {
    &__block {
      padding-top: 50px;
    }

    &__mortgage {
      margin-top: 30px;
    }

    &__blocks {
      padding-bottom: 60px;
    }

    &__figure-top{
      svg{
        width: clamp(160px, 160 * 100vw / 375, 200px);
        height: clamp(80px, 80 * 100vw / 375, 100px);
      }
    }
  }
}