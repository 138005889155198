.advantages-infra-slider {
  &__title-wrap,
  &__translate-wrap {
    display: flex;
  }

  &__translate-wrap {
    flex-direction: column;
  }

  &__translate {
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
  }

  &__title {
    margin-top: 2px;
    color: var(--orange-color);

    font-style: normal;
    font-weight: 700;
    line-height: 135%;
    text-transform: uppercase;
  }

  &__name {
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
    text-transform: uppercase;
  }

  &__slider-wrap {
    position: relative;
  }

  &__waves {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .advantages-infra-slider {
    &__title {
      font-size: 50px;
    }

    &__slider-wrap {
      padding-top: 26px;
      margin-top: 19px;
    }

    &__waves {
      width: 172px;
      height: 188px;
    }

    &__arrows-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-up('s-large') {
  .advantages-infra-slider {
    &__title-wrap {
      justify-content: space-between;
      align-items: flex-end;
    }

    &__translate {
      font-size: 16px;
    }

    &__name {
      font-size: 20px;
      padding-bottom: 10px;
      letter-spacing: 3.2px;
    }

    &__slider-wrap {
      padding-left: var(--grid-spacer-and-indent);
      padding-right: var(--grid-spacer-and-indent);
    }
  }
}

@include respond('s-large') {
  .advantages-infra-slider {
    &__title {
      font-size: 46px;
    }

    &__waves {
      display: none;
    }

    &__slider-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-between('medium', 's-large') {
  .advantages-infra-slider {
    &__arrows-wrap {
      margin-top: 35px;
    }
  }
}

@include respond('medium') {
  .advantages-infra-slider {
    &__name {
      margin-top: 18px;
      letter-spacing: 2.88px;
    }

    &__title {
      font-size: 40px;
    }

    &__slider-wrap {
      padding-top: 12px;
      margin-top: 18px;
    }

    &__waves {
      width: 95px;
      height: 104px;
    }
  }
}

@include respond-down('medium') {
  .advantages-infra-slider {
    &__title-wrap {
      flex-direction: column;
    }

    &__translate {
      font-size: 14px;
    }

    &__name {
      font-size: 18px;
    }

    &__slider {
      &.swiper {
        padding: 0 var(--grid-spacer-and-indent);
      }
    }
  }
}

@include respond-down('small') {
  .advantages-infra-slider {
    &__name {
      margin-top: 16px;
      font-size: 16px;
      letter-spacing: 2.56px;
    }

    &__title {
      font-size: 32px;
    }

    &__waves {
      display: none;
    }

    &__slider-wrap {
      margin-top: 18px;
    }

    &__arrows-wrap {
      display: none;
    }
  }
}