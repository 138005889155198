%text-new-main {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
}

@include respond-up("s-large") {
  %text-new-main {
    --font-size: 16px;
  }
}

@include respond-down("medium") {
  %text-new-main {
    --font-size: 15px;
  }
}