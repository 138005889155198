.about {
  position: relative;

  &__main {
    display: flex;
  }

  &__content {
    display: flex;
    z-index: 1;
  }

  &__tabs-container {
    z-index: 10;
  }

  &__tab {
    &._active {
      .about {
        &__ring-text {
          color: var(--orange-color);
          transform: translateX(0);
        }

        &__ring-index {
          color: var(--orange-color);
        }

        &__ring {
          opacity: 1;

          circle {
            stroke: #E7CDB9;
            stroke-dasharray: 300;
            transition: {
              property: stroke-dasharray, stroke;
              duration: .4s;
            };
          }
        }
      }
    }
  }

  &__tab-link {
    display: flex;
    align-items: center;
  }

  &__ring-text {
    transform: translateX(22px);
    transition: transform .8s, color .6s;
    color: var(--color-grey-02);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__ring-index {
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 26.25px */
    letter-spacing: -0.42px;
    transition: color .6s;
  }

  &__ring-container {
    margin-top: -2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-weight: bold;
    text-align: center;
    letter-spacing: var(--default-letter-spacing);
    color: rgba(127, 139, 141, 0.35);
    width: 68px;
    height: 68px;
    transition: {
      property: color;
      duration: .4s;
    };
  }

  &__ring {
    circle {
      width: 68px;
      height: 68px;
    }

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 0.2s linear, opacity 0.5s;
    transform: rotate(90deg);
    opacity: 1;
    transition: {
      property: opacity, transform;
      duration: 1s;
    };

    circle {
      fill: none;
      stroke: var(--color-grey-02);
      stroke-width: 2;
      stroke-dasharray: 600;
      stroke-dashoffset: 1;

      transition: {
        duration: .4s;
        property: stroke-dashoffset, stroke-dasharray;
      };
    }
  }

  &__bottom-figure {
    display: flex;
  }
}

@include respond-up('large') {
  .about {
    padding-bottom: 120px;

    &__main {
      margin-top: 60px;
    }

    &__tabs-container {
      padding-bottom: 60%;
    }
  }
}

@include respond-up('s-large') {
  .about {
    &__main {
      border-top: 1px solid var(--color-grey-02);
      padding-top: 50px;
    }

    &__tabs-wrap {
      position: sticky;
      top: calc(var(--header-height) + 40px);
    }

    &__tabs-container {
      flex: 0 0 calc(var(--grid-column3) + var(--grid-gap));
      padding-right: var(--grid-gap);
    }

    &__content {
      flex: 0 0 var(--grid-column9);
      justify-content: flex-end;
    }

    &__list {
      width: var(--grid-column9);
    }

    &__bottom-figure {
      position: absolute;
      left: 0;
      bottom: -1px;

      svg {
        width: 394px;
      }

      &_reverse {
        display: none;
      }
    }

    &__to-next {
      width: 52px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 1px solid #08A3A9;
      margin-top: 128px;
    }
  }
}

@include respond('s-large') {
  .about {
    padding-bottom: 100px;

    &__main {
      margin-top: 50px;
    }

    &__tabs-container {
      padding-bottom: 70%;
    }
  }
}

@include respond-up('medium') {
  .about {

  }
}

@include respond('medium') {
  .about {
    padding-bottom: 70px;

    &__main {
      margin-top: 40px;
    }

    &__content {
      margin-top: 40px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 60px;
      }
    }

    &__bottom-figure {
      position: absolute;
      right: 0;
      bottom: -1px;

      svg {
        width: 171px;
      }
    }
  }
}

@include respond-down('medium') {
  .about {
    &__list {
      width: 100%;
    }

    &__to-next {
      display: none;
    }

    &__tab {
      &._active {
        .about {
          &__ring-text {
            border-bottom: 2px solid var(--orange-color);
          }
        }
      }
    }

    &__main {
      flex-direction: column;
    }

    &__ring-container {
      display: none;
    }

    &__ring-text {
      transform: translateX(0);
      transition: border .3s;
      padding: 13px 0;
      margin-right: 19px;
      border-bottom: 2px solid transparent;
      white-space: nowrap;
    }

    &__tabs-wrap {
      background-color: var(--color-add-04);
      transition: transform .3s linear;
      padding-top: 8px;

      &._sticky {
        position: fixed;
        z-index: 50;
        top: 0;
        padding-left: var(--grid-spacer);
        padding-right: var(--grid-spacer);
        left: 0;
        right: 0;
      }
    }

    &__tabs {
      transition: transform .3s linear;
    }

    &__bottom-figure {
      &_normal {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .about {
    padding-bottom: 50px;

    &__main {
      margin-top: 15px;
    }

    &__content {
      margin-top: 30px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }

    &__bottom-figure {
      display: none;
      //position: absolute;
      //right: 0;
      //bottom: -1px;
      //svg{
      //  width: 171px;
      //}
    }
  }
}