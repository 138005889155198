.mortgage {
  &__inner {
    position: relative;
    background: var(--background-color);
  }

  &__content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  &__picture-wrap {
    position: absolute;
    bottom: 0;
    right: 0;
    max-height: 100%;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__title{
    font-style: normal;
    font-weight: 700;
    line-height: 135%; /* 48.6px */
    text-transform: uppercase;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 26.1px */
  }

  &__bottom-figure, &__top-figure, &__deer {
    position: absolute;
    display: flex;
  }

  &__bottom-figure {
    bottom: 0;
    left: 0;
  }

  &__top-figure {
    right: -3px;
    top: -2px;
  }

  &__deer{
    bottom: 0;
    z-index: 2;
  }

  &__button {
    padding-left: 34px;
    padding-right: 34px;
    display: inline-flex;
  }

  &__bottom-figure,
  &__top-figure {
    z-index: 1;
  }

  &__content,
  &__picture-wrap {
    z-index: 10;
  }
}

@include respond-up('large') {
  .mortgage {
    &__inner {
      max-width: var(--grid-column12);
    }

    &__content {
      min-height: 404px;
    }

    &__main {
      width: var(--grid-column6);
      padding: 100px 0 140px 60px;
    }

    &__deer{
      left: calc(50% - 86px);
    }
  }
}

@include respond-up('s-large') {
  .mortgage {
    &__inner{
      height: 483px;
    }

    &__subtitle {
      font-size: 18px;
      margin-top: 22px;
    }

    &__picture-wrap {
      width: 655px;
      height: 463px;
    }

    &__title{
      font-size: 36px;
    }

    &__button {
      margin-top: 45px;
    }

    &__deer{
      svg{
        width: 290px;
        height: 394px;
      }
    }
  }
}

@include respond('s-large') {
  .mortgage {
    &__inner {
      padding-top: 44px;
    }

    &__content {
      min-height: 329px;
    }

    &__main {
      width: calc(var(--grid-column7) + var(--grid-gap));
      padding: 40px 0 40px 50px;
    }

    &__subtitle {
      margin-top: 8px;
    }

    &__deer{
      left: calc(50% - 150px);
    }
  }
}

@include respond-up('medium') {
  .mortgage {
    &__top-figure {
      &_mob {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .mortgage {
    &__content {
      min-height: 362px;
    }

    &__main {
      width: var(--grid-column3);
      padding: 30px 0 30px 40px;
    }

    &__title{
      font-size: 28px;
    }

    &__subtitle {
      font-size: 18px;
      margin-top: 15px;
    }

    &__button {
      margin-top: 25px;
    }

    &__picture-wrap {
      width: calc(var(--grid-column3) - 30px);
    }

    &__deer{
      left: calc(50% - 100px);
      svg{
        width: 207px;
        height: 281px;
      }
    }

    &__top-figure {
      svg{
        width: 358px;
        height: 120px;
      }
    }

    &__bottom-figure {
      svg{
        width: 120px;
        height: 60px;
      }
    }
  }
}

@include respond-down('medium') {
  .mortgage {
    &__subtitle {
      font-size: 15px;
    }
  }
}

@include respond-down('small') {
  .mortgage {
    &__inner {
        overflow: hidden;
    }
    &__main {
      padding: 20px 20px 70vw;
    }

    &__title{
      font-size: 24px;
      width: var(--grid-column3);
      margin-bottom: 16px;
    }

    &__subtitle {
      width: var(--grid-column3);
      margin-top: 16px;
    }

    &__button {
      margin-top: 22px;
    }

    &__picture-wrap {
      display: flex;
      margin-left: 20px;
      width: 100%;
      bottom: 0;
      left: 20px;
    }

    &__bottom-figure{
      display: none;
    }

    &__deer{
      left: -65px;
      svg{
        width: 182px;
        height: 248px;
      }
    }
    &__top-figure {
      width: 120px;
      height: 80px;

      &_desk {
        display: none;
      }

      svg{
        width: 100%;
        height: 100%;
      }
    }
  }
}